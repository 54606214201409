@use '@/scss/underscore' as _;

.overlay {
  z-index: 100;
}

.modal {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 40px;
  bottom: 40px;
  outline: none;
}

.container {
  background: var(--color-dialogue);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  @include _.flex_column(stretch, center);
}

.content {
  padding: _.unit(5);
  flex: 1;
  @include _.flex_column;
}

iframe {
  display: block;

  &.hidden {
    display: none;
  }
}


.footer {
  border-top: 1px solid var(--color-divider);
  @include _.flex_row;
  padding: _.unit(5);

  > * {
    flex: 1;
  }

  > button:first-child {
    margin-right: _.unit(2);
  }
}
