@use '@/scss/underscore' as _;

.form {
  @include _.flex-column;

  > * {
    width: 100%;
  }

  .inputField {
    margin-bottom: _.unit(4);
  }

  .terms {
    margin: _.unit(8) 0 _.unit(4);
  }
}
